<template>
  <div class="bg_light" v-if="traderId">
    <div class="item border_bottom">
      <div>
        <div class="d_flex justify_content_between ">
          <div class="d_flex">
            <div class="icon" v-if="avatar === ''">
              <img src="../../../assets/images/public/ic_my_head.webp" alt="" />
            </div>
            <div class="icon" v-else>
              <img :src="avatar" alt="" />
            </div>
            <div class="title">{{ traderName }}</div>
          </div>
          <div class="d_flex text_light">
            <div class="icon icon_sm" v-show="wechatpayStatus === 1">
              <img src="../../../assets/images/public/ic_my_money_aisle_wechat.webp" alt="" />
            </div>
            <div class="icon icon_sm" v-show="alipayStatus === 1">
              <img src="../../../assets/images/public/ic_my_money_aisle_alipay.webp" alt="" />
            </div>
            <div class="icon icon_sm" v-show="bankcardStatus === 1">
              <img src="../../../assets/images/public/ic_my_money_aisle_bank.webp" alt="" />
            </div>
            <div class="text_primary">
              {{ formatReceiveStatusDesc }}
            </div>
          </div>
        </div>
        <div class="d_flex justify_content_between">
          <div class="content">
            <div>{{ orderTotal }}</div>
            <div class="text_light">交易次数</div>
          </div>
          <div class="content">
            <div>{{ successRate }}</div>
            <div class="text_light">完成率</div>
          </div>
          <div class="content">
            <div>{{ avgConfirmTime }}</div>
            <div class="text_light">平均确认(分钟)</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt_1rem">
      <div class="d_flex justify_content_between text_light_bold">充值限额：￥ {{ minLimit }} - {{ commission }}</div>
      <div class="d_flex justify_content_between text_light_bold">
        <div>提现限额：￥ {{ withdrawMinLimit }} - {{ maxLimit }}</div>
        <!-- <div class="touch" @click="toggleIsRelation">
          <div :class="{ text_primary: !isRelation, text_light: isRelation }" v-if="initStatus === -1">
            {{ !isRelation ? "收藏" : "已收藏" }}
          </div>
          <div
            v-else
            :class="{
              text_primary: !checkisRelation,
              text_light: checkisRelation,
            }"
          >
            {{ !checkisRelation ? "收藏" : "已收藏" }}
          </div>
        </div> -->
      </div>
      <div class="d_flex justify_content_between mt_1rem mb_2rem">
        <button class="btn_primary" @click="getData('recharge')">充值</button>
        <button class="btn_primary" @click="getData('withdraw')">提现</button>
      </div>
      <div class="text_light text_left">卖家备注 : {{ remark }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    avatar: {
      type: String,
      default: "",
    },
    traderId: {
      type: Number,
      default: 0,
    },
    traderName: {
      type: String,
      default: "",
    },
    successRate: {
      type: String,
      default: "",
    },
    receiveStatusDesc: {
      type: String,
      default: "不在线",
    },
    alipayStatus: {
      type: Number,
      default: 0,
    },
    bankcardStatus: {
      type: Number,
      default: 0,
    },
    wechatpayStatus: {
      type: Number,
      default: 0,
    },
    orderTotal: {
      type: Number,
      default: 0,
    },
    avgConfirmTime: {
      type: Number,
      default: 0,
    },
    remark: {
      type: String,
      default: "不在线",
    },
    minLimit: {
      type: Number,
      default: 0,
    },
    commission: {
      type: Number,
      default: 0,
    },
    withdrawMinLimit: {
      type: Number,
      default: 0,
    },
    maxLimit: {
      type: Number,
      default: 0,
    },
    isRelation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkisRelation: null,
      initStatus: -1,
    };
  },
  computed: {
    formatReceiveStatusDesc() {
      return this.receiveStatusDesc === "关闭" ? "交易关闭" : "交易中";
    },
  },
  methods: {
    toggleIsRelation() {
      if (this.initStatus === -1) {
        this.initStatus = 0;
        this.checkisRelation = !this.isRelation;
      } else {
        this.checkisRelation = !this.checkisRelation;
      }
      this.$emit("toggleIsRelation", this.checkisRelation);
    },
    getData(choiceName) {
      this.$emit("getData", choiceName);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.bg_light {
  background: white;
  padding: 0rem 1.2rem 0rem 1.2rem;
}
.d_flex {
  display: flex;
  align-items: center;
}
.flex_column {
  flex-direction: column;
}
.justify_content_between {
  justify-content: space-between;
}
.align_items_start {
  align-items: flex-start;
  text-align: left;
}
.item {
  width: 100%;
  box-sizing: border-box;
  padding: 0.75rem 0rem 1rem 0rem;
  box-sizing: border-box;
}

.content {
  margin-top: 0.75rem;
  font-size: 0.8rem;
  > div {
    margin-bottom: 0.25rem;
  }
}
.title {
  font-weight: bold;
  text-align: left;
  line-height: 1.5;
  margin-left: 0.6rem;
}
.text_light {
  font-size: 0.85rem;
  line-height: 1.5;
  color: var(--gray);
}
.text_left {
  text-align: left;
}
.text_light_bold {
  font-weight: bold;
  color: var(--gray);
}
.text_small {
  font-size: 0.8rem;
}
.text_primary {
  font-size: 0.85rem;
  text-align: right;
  line-height: 1.5;
  color: var(--orange);
  margin-left: 0.5rem;
}
.icon {
  display: block;
  width: 1.8rem;
  transform: translateY(0.1rem);
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
.icon_sm {
  width: 1.2rem;
  margin-left: 0.35rem;
}
.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}
.btn_primary {
  width: 50%;
  background-color: var(--orange);
  border: none;
  color: white;
  padding: 0.5rem 0;
  font-size: 1rem;
  &:nth-child(1) {
    margin-right: 0.6rem;
  }
  &:nth-child(2) {
    margin-left: 0.6rem;
  }
}

.touch {
  padding: 0.5rem 0 0.5rem 2.5rem;
}
</style>
