<template>
  <Container theme="light" :padding="false">
    <template v-if="checkResponseStatus && !isLoading">
      <MyCollectionTraderItem
        v-for="(item, index) in traderRelationsDataArray"
        :key="index"
        :avatar="item.avatar"
        :traderName="item.traderName"
        :successRate="item.successRate"
        :receiveStatusDesc="item.receiveStatusDesc"
        :alipayStatus="item.alipayStatus"
        :bankcardStatus="item.bankcardStatus"
        :wechatpayStatus="item.wechatpayStatus"
        :traderId="item.traderId"
        :orderTotal="item.orderTotal"
        :remark="item.remark"
        :minLimit="item.minLimit"
        :commission="item.commission"
        :withdrawMinLimit="item.withdrawMinLimit"
        :maxLimit="item.maxLimit"
        :isRelation="item.isRelation"
        @toggleIsRelation="toggleIsRelation"
        @getData="getData"
      ></MyCollectionTraderItem>
    </template>
    <NoData v-else-if="!checkResponseStatus && !isLoading"></NoData>

    <!-- 彈窗區塊 -->
    <div v-if="showPopup == true && traderRelationsData.traderId">
      <PopupUserTrade :isTraderRelations="isTraderRelations" :userData="traderRelationsData" :inputName="inputName" :popupValue="popupValue" :choice="choice" @isPopupUserTrade="isPopupUserTrade" @postTraderRelations="postTraderRelations" />
    </div>
    <!-- 快速提現承擔費用 -->
    <div class="PopupCheckPayMoney" v-if="checkFastPopup == true">
      <div class="container__main">
        <div class="title">重要提示</div>
        <div class="content">
          您的提现金额 {{ moneyValue }}，其中
          {{ moneyValue - withdrawCheckoutData["withdrawCommission"] }}
          为常驻用户充值，未经过商户流通。因本平台已向常驻用户支付商户奖励，为防止刷单套取平台奖励，您需要承担此笔费用共计
          {{ withdrawCheckoutData["feeAmount"] }} 元。
        </div>
        <div class="box__btn">
          <div @click="checkFastPopup = false">取消</div>
          <div @click="fastSureTakeMyMoney()">確定</div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import NoData from "@/components/NoData";
import PopupUserTrade from "@/components/PopupUserTrade";
import Container from "../components/Container.vue";
import MyCollectionTraderItem from "../components/MyCollectionTraderItem.vue";
export default {
  components: {
    NoData,
    Container,
    MyCollectionTraderItem,
    PopupUserTrade,
  },
  data() {
    return {
      data: [],
      choice: "recharge",
      selectValue: { name: "全部", value: "" },
      moneyValue: 0,
      inputName: "充值",
      showPopup: false,
      isTraderRelations: true,
      popupValue: 0,
      checkPopup: false,
      checkFastPopup: false,
    };
  },
  watch: {
    traderRelationsData: {
      handler: function(newVal) {
        this.changeSpinnerStatus(true);
        if (newVal) {
          setTimeout(() => {
            this.changeSpinnerStatus(false);
          }, 800);
        }
      },
      immediate: true,
    },
    traderId: {
      handler: function(newVal) {
        if (!newVal) {
          this.$router.push({ path: "/my/myCollection" });
        }
      },
      immediate: true,
    },
    withdrawSubmitData() {
      this.$router.push({
        path: "/waitingForBuyersPayment",
        query: {
          orderNo: this.withdrawSubmitData.orderNo,
          orderId: this.withdrawSubmitData.orderId,
        },
      });
    },
  },
  computed: {
    ...mapState("spinner", ["isLoading"]),
    ...mapState("trader", ["traderRelationsData"]),
    ...mapState("orders", ["withdrawSubmitData", "withdrawCheckoutData"]),
    traderRelationsDataArray() {
      if (!this.traderRelationsData) {
        return [];
      } else {
        let result = [];
        result.push(this.traderRelationsData);
        return result;
      }
    },
    checkResponseStatus() {
      return this.traderRelationsData && this.traderRelationsData !== {} ? true : false;
    },
    traderId() {
      return this.$route.query.traderId ? +this.$route.query.traderId : "";
    },
  },
  methods: {
    ...mapActions("trader", ["getTraders", "changeTraderRelations", "deleteTraderRelations"]),
    ...mapActions("sell", ["getSellTraders"]),
    ...mapActions("buy", ["getBuyTraders", "getBuyFastTrader"]),
    ...mapActions("orders", ["postWithdrawSubmit", "postWithdrawCheckout"]),
    ...mapActions("spinner", ["changeSpinnerStatus"]),
    ...mapActions("trader", ["postTraderRelations"]),
    toggleIsRelation(isRelation) {
      if (!isRelation) {
        this.deleteTraderRelations(this.traderId);
      } else {
        this.changeTraderRelations({
          traderId: this.traderId,
        });
      }
    },
    isPopupUserTrade(isPopupUserTrade) {
      this.showPopup = isPopupUserTrade;
    },
    getData(choiceName) {
      this.showPopup = true;
      this.choice = choiceName;
      this.inputName = choiceName == "recharge" ? "充值" : "提现";
      this.selectValue = { name: "全部", value: "" };
      this.callApi();
    },
    callApi() {
      const self = this;
      self.moneyValue = 0;
      self.data = [];
      if (self.choice == "recharge") {
        self.getBuyTraders({
          channelCode: self.selectValue.value,
        });
        self.data = this.buyData;
      } else {
        self.getSellTraders({
          channelCode: self.selectValue.value,
        });
      }
    },
    fastSureTakeMyMoney() {
      let data = {
        amount: this.moneyValue,
      };
      this.getWithdrawFastTraders(data);
    },
  },
  created() {
    if (!this.traderId) return;
    this.getTraders(this.traderId);
  },
};
</script>
